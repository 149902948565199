import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';
import Layout from '../components/Layout';

export default function ConfirmationPage() {
  return (
    <Layout>
      <Helmet>
        <title>Wealthie — Early access</title>
      </Helmet>
      <Hero title="Thank you!">
        <p>We have added your email address to the waitlist. Stay tuned!</p>
        <p>
          Return to <Link to="/">homepage</Link>.
        </p>
      </Hero>
    </Layout>
  );
}
